import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import Alert from "../../helpers/alert";

export const EditEmailModal = (EmailModalDetails) => {
    const {
        show, handleClose, emailHandleChange, emailSubmit, emailError, userProfile, alert
    } = EmailModalDetails;

    return (
        <Modal show={show} onHide={handleClose}>
            <Alert alert={alert}/>
            <Modal.Header closeButton>
                <Modal.Title>Editing Email Address</Modal.Title>
            </Modal.Header>
            <div className="col-md-6">
                <label className="labels">Enter New Email*</label>
                <input type="email" className="form-control" onChange={emailHandleChange}
                       defaultValue={userProfile?.email}
                       name="newEmail" id="newEmail" placeholder="Enter New Email*"/>
                <div className="text-danger">{emailError.email}</div>
            </div>
            <Modal.Footer>
                <Button variant="secondary" data-testid = "popUpCancelButton" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={emailSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
