import React, {useEffect, useState} from "react"
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {AuthProvider} from "react-oidc-context"
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import RegistrationPage from "./pages/RegistrationPage";
import AuthorizedComponent from "./authorized-component";
import Spinner from "./helpers/spinner";
import "./helpers/spinner.css";
import AdminUpdatePage from "./pages/AdminUpdatePage";

require('dotenv').config()
const oidcUrl = process.env.REACT_APP_STS_OIDC_AUTHORITY;
const responseMode = process.env.REACT_APP_STS_OIDC_RESPONSE_MODE;
const clientId = process.env.REACT_APP_STS_OIDC_CLIENT_ID;
const redirectUrl = process.env.REACT_APP_STS_OIDC_REDIRECT_UI;
const responseType = process.env.REACT_APP_STS_OIDC_RESPONSE_TYPE;
const scope = process.env.REACT_APP_STS_OIDC_SCOPE;

const oidcConfig = {
    response_mode: responseMode,
    client_id: clientId,
    authority: oidcUrl,
    redirect_uri: redirectUrl,
    response_type: responseType,
    scope: scope,
    filterProtocolClaims: true,
}

function App() {

    const [playAnimation, setPlayAnimation] = useState(true);
    const [alert, setAlert] = useState(null);
    const showAlert = (message, type) => {
        setAlert(({
            msg: message,
            type: type
        }))
        setTimeout(() => {
            setAlert(null);
        }, 4000)
    }

    const props = {alert, showAlert};

    useEffect(() => {
        const onPageLoad = () => {
            setPlayAnimation(false);
        };

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        }
    }, []);

    if (!playAnimation) {
        return (
            <div className="pos-center">
                <Spinner/>
            </div>
        );
    }

    return (
        <Router>
            <div>
                <Switch>
                    <Route
                        path="/register"
                        render={() => <RegistrationPage {...props}/>}
                    />
                    <Route
                        path="/admin/update/profile"
                        render={() => <AdminUpdatePage {...props}/>}
                    />
                    <AuthProvider {...oidcConfig}>
                        <AuthorizedComponent {...props}/>
                    </AuthProvider>
                </Switch>
            </div>
        </Router>
    )
}

export default App;
