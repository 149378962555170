import moment from "moment";

const isValidDate = (dateString) => {
    const isValidDate = moment(dateString, 'MM/DD/YYYY', true).isValid();

    if (isValidDate) {
        if(new Date(dateString).getTime() > new Date().getTime()){
            return "Invalid date of birth!"
        }
        else {
            return ""
        }
    }
    return "Date should be in MM/DD/YYYY format.";
}

const checkValue = (str, max) => {
    if (str.charAt(0) !== '0' || str === '00') {
        let num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
};

const getHandleInput = (event) => {
    let input = event.target.value;
    const key = event.nativeEvent.inputType;

    if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
        return input;
    }

    if (key === 'insertLineBreak' || key === 'insertParagraph') {
        return '';
    }

    if (/\D\/$/.test(input)) {
        return input.substr(0, input.length - 3);
    }

    const values = input.split('/').map((v) => v.replace(/\D/g, ''));
    if (values[0]) values[0] = checkValue(values[0], 12);
    if (values[1]) values[1] = checkValue(values[1], 31);
    const output = values.map((v, i) => (v.length === 2 && i < 2 ? v + '/' : v));
    return (output.join('').substr(0, 14));
};

const getHandleBlur = (event) => {
    const input = event.target.value;
    const values = input.split('/').map((v) => v.replace(/\D/g, ''));
    let output = '';

    if (values.length === 3) {
        const year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
        const month = parseInt(values[0]) - 1;
        const day = parseInt(values[1]);
        const d = new Date(year, month, day);
        if (!isNaN(d)) {
            const dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
            output = dates
                .map((v) => {
                    v = v.toString();
                    return v.length === 1 ? '0' + v : v;
                })
                .join('/');
        }
    }
    return output;
};

export {isValidDate, checkValue, getHandleInput, getHandleBlur};
