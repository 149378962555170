import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from "react-bootstrap/Card";
import Barcode from 'react-barcode';
import {userProfileService} from "../service/userProfile/userProfile.service";
import loyaltyLogo from '../assets/images/loyalty.png';

export const LoyaltyPage = (props) => {
    const [loyaltyNumber, setLoyaltyNumber] = useState({loyaltyNo: null});

    useEffect(() => {
            if (props?.loyaltyId !== undefined && props?.loyaltyId) {
                loyaltyNumber.loyaltyNo = props?.loyaltyId;
                setLoyaltyNumber(loyaltyNumber);
            }

            if (loyaltyNumber.loyaltyNo === null && props?.phoneNo !== null && props?.phoneNo !== undefined && props?.id !== undefined && props?.id !== null) {
                userProfileService.getLoyaltyNumber(props?.phoneNo, props?.id)
                    .then((data) => {
                        if (data) {
                            setLoyaltyNumber(data);
                        }
                    });
            }
        },
        [loyaltyNumber, loyaltyNumber.loyaltyNo, props.id, props.loyaltyId, props.phoneNo]);
    return (
        <div className="text-center m-6-auto">
            <div className="p-1 py-5">
                <Card>
                    <Card.Body>
                        <div className="text-center m-6-auto section-title">
                            <h4>Loyalty Card</h4>
                        </div>
                        <Card.Text>
                            With MyThyme it's your time to shop and save!
                        </Card.Text>
                        <img style={{width: "50%"}} src={loyaltyLogo} alt="MyThyme Loyalty: Real Rewards"/>
                        {loyaltyNumber.loyaltyNo ?
                            <Barcode width={2}
                                     height={50}
                                     format="CODE128"
                                     displayValue={true}
                                     font="monospace"
                                     textAlign="center"
                                     value={loyaltyNumber ? loyaltyNumber.loyaltyNo : '0000000'}
                                     textPosition="bottom"/> :
                            ""
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
        ;
}


