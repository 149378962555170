import React from "react";

export const AddressList = (addressRequest) => {
    const {
        addresses, addressUpdatehandleShow, deleteItem
    } = addressRequest;

    return (

        <div>
            <table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th></th>
                    <th>Address</th>
                    <th>City</th>
                    <th>Zip Code</th>

                </tr>
                </thead>

                <tbody>
                {addresses && addresses.map((item, index) =>
                    <tr key={index}>
                        <td style={{paddingTop: "0.7rem"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                        </td>
                        <td style={{paddingTop: "0.8rem"}}> {item?.address1}</td>
                        <td style={{paddingTop: "0.8rem"}}>{item?.city}</td>
                        <td style={{paddingTop: "0.8rem"}}>{item?.zipCode}</td>
                        <td>
                            <button type="button" className="optionBtn btn-warning btn-xs"
                                    onClick={addressUpdatehandleShow(item.id)}>Update
                            </button>
                        </td>
                        <td>
                            <button type="button" className="optionBtn btn-danger btn-xs"
                                    onClick={deleteItem(index, item.id)}>Delete
                            </button>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}
