import React, {useState, useEffect} from "react";
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AddPreferredLocationModal} from "../components/modals/AddPreferredLocationModal";
import Alert from "../helpers/alert";

export const PreferredStoreLocation = (preferredStoreDetails) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const {alert, showAlert} = preferredStoreDetails;
    let {oldStore, storeList} = preferredStoreDetails;
    const [newStore, setNewStore] = useState(null);
    const setTempAlert = (msg, status) =>{
        showAlert(msg, status);
    };

    const handleClose = (newStore) => {
        if (newStore) {
            setNewStore(newStore);
        }
        setShow(false);
    }

    preferredStoreDetails = {
        ...preferredStoreDetails, show, handleClose, storeList, setTempAlert
    }
    useEffect(() => {
        if (oldStore) {
            setNewStore(oldStore);
        }
    }, [oldStore]);

    return (
        <div className="text-center m-6-auto">
            <Alert alert={alert}/>
            <div className="p-1 py-5">
                <Card>
                    <Card.Body>
                        <div className="form-group">
                            <div className="text-center m-6-auto section-title">
                                <h4>Preferred Store Location</h4>
                            </div>
                            {oldStore ? <div>
                                <ul className="no-bullets">
                                    <li>
                                        <h6>{newStore?.ecomName}</h6>

                                    </li>
                                    <li>{newStore?.address1 + " " + newStore?.ecomName}, {newStore?.postalCode}</li>

                                    <li>
                                        {newStore?.voicePhone}
                                    </li>
                                </ul>

                            </div> : ''
                            }
                            <button className="btn btn-outline-success" type="button" onClick={handleShow}
                                    style={{width: "100%", borderRadius: "0"}}>
                                Change Store
                            </button>
                            <AddPreferredLocationModal {...preferredStoreDetails}/>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <AddPreferredLocationModal {...preferredStoreDetails}/>
        </div>
    );
}


