import {fetchWrapper} from "../../helpers/fetch.wrapper";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getStoreLocationService = {getStoreLocator};

    async function getStoreLocator() {

    return fetchWrapper.get(`${baseUrl}/stores`
    )
        .then(response => {
            return response;
        });
}