import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React from "react";
import Alert from "../../helpers/alert";

export const UpdateAddressModal = (updateAddressDetails) => {

    const {
        addressUpdateShow,
        addressUpdateHandleClose,
        addressHandleChange,
        updateAddress,
        addressDetails,
        customerId,
        alert
    } = updateAddressDetails;

    return (
        <Modal show={addressUpdateShow} onHide={addressUpdateHandleClose}>
            <Alert alert={alert}/>
            <Modal.Header closeButton>
                <Modal.Title>Update Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <div className="p-sm-1 py-sm-1">
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    <label className="labels">First Name*</label>
                                    <input
                                        type="text" className="form-control" onChange={addressHandleChange}
                                        id="firstName" name="firstName"
                                        placeholder="First Name"
                                        defaultValue={addressDetails?.firstName}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">Last Name*</label>
                                    <input id="lastName" name="lastName"
                                           type="text" className="form-control" onChange={addressHandleChange}
                                           defaultValue={addressDetails?.lastName}
                                           placeholder="Last Name"/>
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Address Line
                                        1*</label>
                                    <input id="address1" name="address1"
                                           type="text" className="form-control" onChange={addressHandleChange}
                                           defaultValue={addressDetails?.address1}
                                           placeholder="Enter address line 1"/>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Address Line 2</label>
                                    <input id="address2" name="address2"
                                           type="text" className="form-control" onChange={addressHandleChange}
                                           defaultValue={addressDetails?.address2}
                                           placeholder="Enter address line 2"/>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">City*</label>
                                    <input onChange={addressHandleChange} id="city" name="city"
                                           type="text" className="form-control" placeholder="City"
                                           defaultValue={addressDetails?.city}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">
                                        State*
                                    </label>
                                    <input onChange={addressHandleChange} id="state" name="state"
                                           type="text" className="form-control" placeholder="State"
                                           defaultValue={addressDetails?.state}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="labels">Zip Code*</label>
                                    <input onChange={addressHandleChange} id="zipCode" name="zipCode"
                                           type="number" className="form-control" placeholder="Zip Code"
                                           defaultValue={addressDetails?.zipCode}
                                    />
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Phone*</label>
                                    <input onChange={addressHandleChange} id="phoneNo" name="phoneNo"
                                           type="number" className="form-control" placeholder="Phone Number"
                                           defaultValue={addressDetails?.phoneNo}
                                    />
                                </div>

                                {/*<div className="col-md-12">*/}
                                {/*    <label className="labels">Delivery*/}
                                {/*        Instructions</label>*/}
                                {/*    <textarea onChange={addressHandleChange} id="deliveryInstructions"*/}
                                {/*              name="deliveryInstructions"*/}
                                {/*              type="text" className="form-control" rows={3}*/}
                                {/*              defaultValue={addressDetails?.deliveryInstructions}*/}
                                {/*              placeholder="Delivery instructions"*/}

                                {/*    /></div>*/}
                            </div>

                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={addressUpdateHandleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={updateAddress(customerId)}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>);
}
