import Modal from "react-bootstrap/Modal";
import React from "react";


export const SummaryModal = (props) => {

    const {comparisonDetails, showSummary, handleSummaryClose} = props;
    return (
        <Modal show={showSummary} onHide={handleSummaryClose}>

            <Modal.Header closeButton>
                <Modal.Title>Comparison Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className={"Summary"} style={{height: "8em", overflow: "auto"}}>
                        <div
                            className={"p-9 mb-1 " + ("Matched"  === comparisonDetails?.loyaltyId ? "bg-success" : "bg-danger") + " text-white"}>loyalty
                            Id {"Matched"  === comparisonDetails?.loyaltyId ? "Matched" : "Not Matched"}
                        </div>
                        <div
                            className={"p-9 mb-1 " + ("Matched"  === comparisonDetails?.phoneNo  ? "bg-success" : "bg-danger") + " text-white"}>Phone
                            Number {"Matched"  === comparisonDetails?.phoneNo ? "Matched" : "Not Matched"}
                        </div>
                        <div
                            className={"p-9 mb-1 " + ("Matched"  === comparisonDetails?.email? "bg-success" : "bg-danger") + " text-white"}>Email
                            {"Matched"  === comparisonDetails?.email ? "Matched" : "Not Matched"}
                        </div>
                    </div>
            </Modal.Body>
        </Modal>);
}
