import {useState, useEffect} from "react";

export const usePasswordValidation = ({

                                          firstPassword = "",
                                          secondPassword = "",
                                          requiredLength = 6,


                                      }) => {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [lowerCase, setLowerCase] = useState(null);
    const [specialChar, setSpecialChar] = useState(null);
    const [passwordMatch, setPasswordMatch] = useState(null);

    useEffect(() => {

        setValidLength(firstPassword.length >= requiredLength ? true : false);
        setUpperCase(firstPassword.toLowerCase() !== firstPassword);
        setLowerCase(firstPassword.toUpperCase() !== firstPassword);
        setHasNumber(/\d/.test(firstPassword));
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(firstPassword));
        setPasswordMatch(firstPassword === secondPassword);
    }, [firstPassword, secondPassword, requiredLength]);

    return [validLength, hasNumber, upperCase, lowerCase, specialChar, passwordMatch];
};
