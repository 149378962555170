import React, {useState} from 'react'
/*import Navigation from "../navigation/Navigation";*/
import Select from "react-dropdown-select";
import ftmlogo from '../../assets/images/fresh-thyme-market-logo.png';
import {useEffect} from "react";
import Alert from "../../helpers/alert";
import {getHandleBlur, getHandleInput} from "../../utils/dateValidator";

const loginLink = process.env.REACT_APP_STS_REDIRECT_LOGIN_LINK;
const termsConditions = process.env.REACT_APP_STS_TERM_CONDITIONS;
const privacyPolicy = process.env.REACT_APP_STS_PRIVACY_POLICY;
export const Registration = (props) => {
    const {
        handleSubmit,
        handleChange,
        error,
        validLength,
        upperCase,
        lowerCase,
        specialChar,
        hasNumber,
        stores,
        queryString,
        changeStoreLocation,
        registering, alert
    } = props;
    // const loginLink = queryString ? `/login${queryString}` : `/login`;

    const query = new URLSearchParams(queryString);
    const [defaultStore, setDefaultStore] = useState();
    const storeNumber = Number(query.get("store_number"));
    const [input, setInput] = useState('');
    useEffect(() => {

        if (storeNumber && stores.length > 0) {
            setDefaultStore(stores.find(store => store.storeNumber === storeNumber));
            changeStoreLocation({
                target: {
                    name: "storeLocation",
                    value: defaultStore?.originalEcomName
                }
            }, defaultStore?.storeNumber)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeNumber, stores.length]);


    function handleStoreChange(values) {
        changeStoreLocation({
            target: {
                name: "storeLocation",
                value: values[0].originalEcomName
            }
        }, values[0].storeNumber);
    }

    const handleInput = (event) => {
        if (event.target.value.length <= 10) {
            const value = getHandleInput(event);
            handleChange({target: {name: 'birthday', value: value}})
            setInput(value);
        }
    };

    const handleBlur = (event) => {
        if (event.target.value.length < 11) {
            const value = getHandleBlur(event);
            if (value) {
                handleChange({target: {name: 'birthday', value: value}})
                setInput(value);
            }
        }
    };

    return (
        <div className="text-center m-6-auto">

            <form>
                <img src={ftmlogo} alt="Fresh Thyme Market logo"/>
                <h4 id="sub_head" style={{fontFamily: "A Love of Thunder", color: "#007935"}}>Register new account</h4>
                <h6 id="note">All fields required *
                </h6>
                <Alert alert={alert}/>
                <div className={"row"}>
                    <div className="col-6">
                        <div className="form-group">
                            <input
                                type="text"
                                name="firstName"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="First Name *"
                                id="firstName"
                            />
                            <div className="text-danger">{error.firstName}</div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <input
                                type="text"
                                name="lastName"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Last Name *"
                                id="lastName"/>
                            <div className="text-danger">{error.lastName}</div>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        name="email"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Email *"
                        id="email"/>
                    <div className="text-danger">{error.email}</div>
                </div>

                <div className="form-group">
                    <input
                        type="number"
                        name="phoneNo"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Primary Phone Number *"
                        id="phoneNo"/>
                    <div className="text-danger">{error.phoneNo}</div>
                </div>

                <div className="form-group">
                    <input
                        type="text"
                        name="birthday"
                        className="form-control"
                        placeholder="Date of Birth (MM/DD/YYYY)"
                        id="birthday"
                        value={input}
                        onChange={(e) => handleInput(e)}
                        onBlur={handleBlur}
                    />
                    <div
                        className={error.birthday ? "text-danger" : "text-success"}>{error.birthday ? error.birthday : error.birthdaySuccess}</div>
                </div>

                <div className="form-group">
                    {stores.length > 0 && defaultStore ?
                        <Select style={{border: "2px solid #000", borderRadius: "0"}}
                                onChange={values => handleStoreChange(values)}
                                options={stores}
                                dropdownHeight="350px"
                                labelField="ecomName"
                                valueField="ecomName"
                                name="storeLocation"
                                placeholder="Select Store *"
                                values={[defaultStore]}
                                disabled/>
                        :
                        <Select style={{border: "2px solid #000", borderRadius: "0"}}
                                onChange={values => handleStoreChange(values)}
                                options={stores}
                                dropdownHeight="350px"
                                labelField="ecomName"
                                valueField="ecomName"
                                name="storeLocation"
                                placeholder="Select Store *"
                        />
                    }
                    <div className="text-danger">{error.storeLocation}</div>
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        name="password"
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Password *"
                        id="password"/>
                    <div className="text-danger">{error.password}</div>
                </div>
                <div style={{marginRight: "1.5rem", marginLeft: "1.5rem"}}>
                    <div className="form-group" style={{
                        textAlign: "left",
                        border: "1px solid #555153",
                        background: "#fff9e8",
                        padding: "0.8rem",
                    }}>
                        <ul style={{listStyleType: "none", paddingLeft: "0", marginBottom: "0", color: "#525A5E"}}>
                            <li>
                                <input className="ft-checkbox" type="checkbox" id="validLength" name="validLength"
                                       checked={!!validLength} readOnly>
                                </input>
                                6 or more characters
                            </li>
                            <li>
                                <input className="ft-checkbox" type="checkbox" id="upperCase" name="upperCase"
                                       checked={!!upperCase} readOnly>
                                </input>
                                One uppercase character
                            </li>
                            <li>
                                <input className="ft-checkbox" type="checkbox" id="lowerCase" name="lowerCase"
                                       checked={!!lowerCase} readOnly>
                                </input>
                                One lowercase character
                            </li>
                            <li>
                                <input className="ft-checkbox" type="checkbox" id="specialChar" name="specialChar"
                                       style={{float: "left", width: "auto"}}
                                       checked={!!specialChar} readOnly>
                                </input>
                                One symbol
                            </li>
                            <li>
                                <input className="ft-checkbox" type="checkbox" id="hasNumber" name="hasNumber"
                                       style={{float: "left", width: "auto", marginRight: "0.2rem"}}
                                       checked={!!hasNumber} readOnly>
                                </input>
                                One number
                            </li>
                        </ul>
                    </div>
                    <p style={{fontSize: "0.7em"}}>By creating an account, you agree to our <a
                        style={{color: "#0777FB", textDecoration: "none"}}
                        href={termsConditions}
                        target="_blank"
                        rel="noopener noreferrer">Terms of Use</a>
                        &nbsp;and <a style={{color: "#0777FB", textDecoration: "none"}}
                                     href={privacyPolicy}
                                     target="_blank"
                                     rel="noopener noreferrer">Privacy Policy</a></p>
                </div>
                <button id="sub_btn" type="submit"
                        style={{backgroundColor: "#007935", height: "50px", fontSize: "1.2em"}}
                        onClick={handleSubmit}
                        disabled={registering}>Create Account {registering ?
                    <i className="fa fa-spinner fa-spin"></i> : ""}
                </button>
                <p><a href={loginLink} style={{color: "#007935", textDecoration: "none"}}>Go Back</a></p>
            </form>
        </div>
    );
}
