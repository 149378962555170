import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React from "react";
import Alert from "../../helpers/alert";

export const ProfileModal = (props) => {

    const {
        handleSubmit,
        handleChange,
        userProfile, isChanged, error,
        showProfilePopUp, alert
    } = props;

    return (
        <Modal show={showProfilePopUp}>
            <Alert alert={alert}/>
            <Modal.Header>
                <Modal.Title>Personal Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <div className="p-1">
                            <div className="form-group">
                                <label className={"userLabel"}>First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    className="form-control"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    id="firstName"
                                    defaultValue={userProfile?.firstName}
                                />
                                <div className="text-danger">{error.firstName}</div>
                            </div>

                            <div className="form-group">
                                <label className={"userLabel"}>Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    className="form-control"
                                    placeholder="Last Name"
                                    onChange={handleChange}
                                    id="lastName"
                                    defaultValue={userProfile?.lastName}/>
                                <div className="text-danger">{error.lastName}</div>
                            </div>
                            <div className="form-group">
                                <label className={"userLabel"}>Primary Phone Number</label>
                                <input
                                    type="number"
                                    name="phoneNo"
                                    className="form-control"
                                    onChange={handleChange}
                                    placeholder="Primary Phone Number"
                                    id="phoneNo"
                                    defaultValue={userProfile?.phoneNo}
                                />
                                <div className="text-danger">{error.phoneNo}</div>
                            </div>

                            <div className="gap">
                                <button className="btn btn-primary profile-btn" type="button" onClick={handleSubmit}
                                        disabled={isChanged}
                                        style={{width: "100%", borderRadius: "0", backgroundColor: "#1A5D0A"}}>Save
                                    Changes
                                </button>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
