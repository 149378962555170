import {hasAuthParams, useAuth} from "react-oidc-context";
import React, {useEffect} from "react";
import {Route} from "react-router-dom";
import {AccountSettings} from "./components/accountSettings/AccountSettings";
import Spinner from "./helpers/spinner";
import "./helpers/spinner.css";

function AuthorizedComponent(customProps) {
    const auth = useAuth();

    useEffect(() => {
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }

    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect, auth]);

    useEffect(() => {
        return auth.events.addAccessTokenExpired((data) => {
        })

    }, [auth.events, auth]);

    if (auth.activeNavigator) {
        return (
            <div className="pos-center">
                <Spinner/>
            </div>
        );
    }

    if (auth?.user) {
        const url = window.location.href.split("?")[0];
        window.history.replaceState({},
            url, url);
    }
    return (
        <div>
            <Route
                path="/account-settings"
                render={() => <AccountSettings {...customProps}/>}
            />
        </div>
    )

};
export default AuthorizedComponent;
