import './AccountSettings.css'
import ftmlogo from "../../assets/images/fresh-thyme-market-logo.png"

import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Row, Col} from "react-bootstrap";
import {PreferredStoreLocation} from "../../pages/PreferredStoreLocationPage";
import {ProfilePage} from "../../pages/ProfilePage";
import {AddressesPage} from "../../pages/AddressesPage";
import {LoyaltyPage} from "../../pages/LoyaltyPage";
import {userProfileService} from "../../service/userProfile/userProfile.service";
import {getStoreLocationService} from "../../service/storeLocator/storeLocator.service";
import {AddPreferredLocationModal} from "../modals/AddPreferredLocationModal";
import {useAuth} from "react-oidc-context";

const defaultRedirectUrl = process.env.REACT_APP_REDIRECT_URI;
// const sessionStorageKey = process.env.REACT_APP_STS_SESSION_STORAGE_KEY;

export const AccountSettings = (props) => {
    const auth = useAuth();
    const [customerInfo, setCustomerInfo] = useState(null);
    const [store, setStore] = useState(null);
    const [storeList, setStoreList] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = (newStore) => {
        if (newStore) {
            setStore(newStore);
        }
        setShow(false);
    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (auth?.user) {
            userProfileService.customerInfo().then(data => {
                if (data) {
                    setCustomerInfo(data);
                    getStoreLocationService.getStoreLocator()
                        .then((storeDetails) => {
                            if (storeDetails) {
                                if (data.storeNumber) {
                                    const storeDetail = storeDetails.Stores.find(value => value.storeNumber === Number(data.storeNumber));
                                    storeDetail.email = data.email;
                                    setStore(storeDetail);
                                } else {
                                    setStore({'email': data.email});
                                    handleShow();
                                }
                                const stores = storeDetails.Stores.filter((store) => {
                                    if (store.ecomName) {
                                        return store;
                                    }
                                    return null;
                                });
                                setStoreList(stores);
                            }
                        })
                }
            })

        }
    }, [auth.user]);

    const preferredStoreDetails = {...props, show, handleClose, oldStore: store, storeList};

    const updateProps = (userProfile) => {
       setCustomerInfo(userProfile);
    }
    props = {...props, ...customerInfo, updateProps}
    if (auth?.user) {
        return (
            <div className="text-center m-6-auto">
                <div className="text-center m-6-auto" style={{marginTop: "20px"}}>
                    <div><img src={ftmlogo} alt="Fresh Thyme Market logo"/></div>
                    <div style={{marginTop: "20px"}}>
                <span className="number">
                    Hello {customerInfo?.firstName}
                </span>
                    </div>
                </div>

                {/*<div className="text-center m-6-auto">*/}
                {/*    <span className="follow">Member Since 2021</span>*/}
                {/*</div>*/}

                <AddPreferredLocationModal {...preferredStoreDetails}/>

                <div className={"nav-profile"}>

                    <Row>
                        <Col>
                            <Tabs>
                                <Tab eventKey="profile" title="Profile" defaultactivekey="Profile">
                                    <ProfilePage {...props}/>
                                </Tab>
                                <Tab eventKey="address" title="Address">
                                    <AddressesPage {...props}/>
                                </Tab>
                                <Tab eventKey="preferredStoreLocation" title="Preferred Store">
                                    <PreferredStoreLocation {...preferredStoreDetails}/>
                                </Tab>
                                <Tab eventKey="loyalty" title="Loyalty">
                                    <LoyaltyPage {...customerInfo}/>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

                </div>

                <div className="text-center m-6-auto">
                    <a className="btn btn-primary profile-back-btn" href={defaultRedirectUrl}>
                        Go Back
                    </a>
                </div>
            </div>

        );
    }
    return (
        <div className="text-center m-6-auto">
            Loading now
        </div>);

}

