import React, {useState, useEffect} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {userProfileService} from "../service/userProfile/userProfile.service";
import {AddAddressModal} from "../components/modals/AddAddressModal"
import {UpdateAddressModal} from "../components/modals/UpdateAddressModal";
import {AddressList} from "../components/address/AddressList"
import Alert from "../helpers/alert";

export const AddressesPage = (prop) => {
    const [customerId, setCustomerId] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [addressUpdateShow, setAddressUpdateShow] = useState(false);
    const addressUpdateHandleClose = () => setAddressUpdateShow(false);
    const [addresses, setAddress] = useState([]);
    const [addressDetails, setAddressDetails] = useState([]);
    const {alert, showAlert} = prop;
    const addressUpdatehandleShow = (id) => () => {
        setAddressDetails(addresses.find(address => address.id === id));
        setAddressUpdateShow(true);
        setCustomerId(id);
    }

    const propsLength = Object.keys(prop).length;
    useEffect(() => {

        if (propsLength > 0) {
            setAddressDetails({customerId: prop?.email});
            userProfileService.getCustomerAddress(prop.email).then(data => {
                if (data) {
                    setAddress(data);
                }
            })
        }

    }, [prop.email, propsLength]);

    const addAddress = (event) => {
        event.preventDefault();
        return userProfileService.saveAddress(addressDetails).then(data => {
            if (data && data.success === true) {
                setShow(false);
                setAddress([{...addressDetails, id: data.id}, ...addresses]);
                showAlert("Successfully added new address", "success");
                // window.location.reload();
            } else {
                showAlert('Invalid Inputs', "warning");
            }

        });
    }
    const updateAddress = (id) => () => {

        return userProfileService.updateAddress(addressDetails, id).then(data => {
            if (data && data.success === true) {
                setAddressUpdateShow(false);
                const index = addresses.findIndex(element => element.id === id);
                if (index > -1) {
                    addresses[index] = addressDetails;
                    setAddress(addresses);
                    showAlert("Successfully updated address", "success");
                }else
                {
                    showAlert("Unable to update address", "warning");
                }
            } else {
                showAlert('Invalid Inputs', "warning");
            }
        });
    }

    const deleteItem = (index, id) => () => {
        userProfileService.deleteAddress(id).then(data => {
            if (data?.success) {
                setAddress((items) => items.filter((_, i) => i !== index));
                showAlert("Successfully Deleted", "success");
            } else {
                showAlert("Something went wrong", "warning");
            }
        })
    }

    const addressHandleChange = (event) => {
        addressDetails[event.target.name] = event.target.value;
        setAddressDetails(addressDetails);
    }

    const addressRequest = {
        addresses, addressUpdatehandleShow, deleteItem
    };

    const addAddressDetails = {
        show, handleClose, addressHandleChange, addAddress, alert
    };

    const updateAddressDetails = {
        addressUpdateShow,
        addressUpdateHandleClose,
        addressHandleChange,
        updateAddress,
        addressDetails,
        customerId, alert
    };

    return (
        <div className=" text-center m-6-auto">
            <Alert alert={alert}/>
            <div className=" p-1 py-5">
                <div className="text-center m-6-auto section-title">
                    <h4>Address</h4>
                </div>
                <p>
                    Add and delete your address for quick checkout
                </p>
                <AddressList {...addressRequest}/>
                <button style={{width: "100%", borderRadius: "0"}} className=" btn btn-outline-success" type=" button"
                        onClick={handleShow}>
                    + Add A New Address
                </button>

                <AddAddressModal {...addAddressDetails}/>
                <UpdateAddressModal {...updateAddressDetails}/>

            </div>
        </div>
    );
}

