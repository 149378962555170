import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useState, useEffect} from "react";
import Select from "react-dropdown-select";
import {userProfileService} from "../../service/userProfile/userProfile.service";
import 'bootstrap/dist/css/bootstrap.css';

export const AddPreferredLocationModal = (preferredStoreDetails) => {

    const {show, handleClose, oldStore, storeList, setTempAlert} = preferredStoreDetails;
    const [store, setStore] = useState({storeNumber: '', storeLocation: ''});
    const [newStore, setNewStore] = useState(null);
    const [stores, setStores] = useState([]);
    const [processing, setProcessing] = useState(false);
    const changeStoreLocation = (newStore) => {
        setStore({storeNumber: newStore.storeNumber, storeLocation: newStore.ecomName});
        setNewStore(newStore);
    }

    useEffect(() => {
        if (storeList) {
            setStores(storeList);
        }

    }, [storeList]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setProcessing(true);
        userProfileService.updateStore(store, oldStore.email).then((data) => {
            setProcessing(false);

            if (data && data.message === "Customer preferred store updated successfully") {
                newStore.email = oldStore.email;
                handleClose(newStore);
                setTempAlert(data.message, "success");
            } else {
                handleClose();
                setTempAlert("Cannot update store at the moment, please try again later", "danger")
            }
        });
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select your preferred location</Modal.Title>
                </Modal.Header>
                <form >
                    <div className="col-md-6">
                        <div className="form-group" style={{width: '300px'}}>
                            <label htmlFor="storeLocation">Select Store*</label>
                            <Select onChange={values => changeStoreLocation(values[0])}
                                    options={stores}
                                    dropdownHeight="350px"
                                    labelField="ecomName"
                                    valueField="ecomName"
                                    name="storeLocation"
                                    placeholder="Select Store"

                            />
                            <Button variant="primary" type="submit" style={{width: '300px'}}
                                    onClick={handleSubmit}
                                    disabled={processing}>
                                {processing ? 'Processing' : 'Save'}
                            </Button>
                        </div>
                    </div>
                </form>
                <Modal.Footer>

                </Modal.Footer>

            </Modal>
        </div>
    );
}
