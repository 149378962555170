import {fetchWrapper} from "../../helpers/fetch.wrapper";

const baseUrl = process.env.REACT_APP_BASE_URL;
const oidcUrl = process.env.REACT_APP_STS_OIDC_AUTHORITY;
export const authService = {
    login,
    registration,
    resetPassword,
    checkToken,
    changePassword,
    signInOIDC,
    updateProfile,
    lookUpAccount, loginAdmin,
    updateWynshopLoyaltyId,deleteCustomerRecord
}

async function login(loginDetail) {
    return fetchWrapper.post(`${baseUrl}/customer/authenticate`,
        loginDetail)
        .then(response => {
            return response;
        });
}

async function registration(registrationDetails) {

    return fetchWrapper.post(`${baseUrl}/customer/registration`,
        registrationDetails)
        .then(response => {
            return response;
        });
}

async function lookUpAccount(lookUpData) {

    return fetchWrapper.get(`${baseUrl}/admin/member/lookup/details?fieldType=${encodeURIComponent(lookUpData.fieldType)}&searchValue=${encodeURIComponent(lookUpData.searchValue)}`)
        .then(response => {
            return response;
        });
}

async function loginAdmin(loginCredentials) {

    return fetchWrapper.login(`${baseUrl}/admin/login`, loginCredentials)
        .then(response => {
            return response;
        });
}

async function deleteCustomerRecord (email) {

    return fetchWrapper.post(`${baseUrl}/admin/delete/profile/${email}`,{})
        .then(response => {
            return response;
        });
}

async function updateWynshopLoyaltyId(customerId) {

    return fetchWrapper.post(`${baseUrl}/admin/wynshop/fix/loyalty-id/${customerId}`, {})
        .then(response => {
            return response;
        });
}

async function updateProfile(updateDetails) {

    return fetchWrapper.post(`${baseUrl}/admin/customer/update`,
        updateDetails)
        .then(response => {
            return response;
        });
}


async function resetPassword(passwordResetDetails) {

    return fetchWrapper.post(`${baseUrl}/customer/reset-password`,
        passwordResetDetails)
        .then(response => {
            return response;
        });
}


async function checkToken(token) {

    return fetchWrapper.post(`${baseUrl}/customer/check-token`,
        token)
        .then(response => {
            return response;
        });
}

async function changePassword(changePassword) {

    return fetchWrapper.post(`${baseUrl}/customer/change-password`,
        changePassword)
        .then(response => {
            return response;
        });
}


async function signInOIDC(oidcPayload) {
    return fetchWrapper.oidcpost(`${oidcUrl}/signin-oidc-sts`,
        oidcPayload)
        .then(response => {
            return response;
        }, error => {
            alert("sign in Oidc failed")
        });
}
